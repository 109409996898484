// Here you can add other styles

.btn-primary,
.btn-primary.active,
.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active
{
  color: $white;
}

@media(max-width: 600px) {
  input {
    font-size: 16px !important;
  }
}

$pretty--class-name: pretty;
$pretty--color-primary: $white !important;