input.form-control {
  padding: 0.375rem 0.6rem;
}
button {
  outline: none !important;
}
.modal-xxl {
  min-width: 98%;
  margin: 1% !important;
}
